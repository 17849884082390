@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 240 10% 3.9%;
    --card: 0 0% 100%;
    --card-foreground: 240 10% 3.9%;
    --popover: 0 0% 100%;
    --popover-foreground: 240 10% 3.9%;
    --primary: 240 5.9% 10%;
    --primary-foreground: 0 0% 98%;
    --secondary: 240 4.8% 95.9%;
    --secondary-foreground: 240 5.9% 10%;
    --muted: 240 4.8% 95.9%;
    --muted-foreground: 240 3.8% 46.1%;
    --accent: 240 4.8% 95.9%;
    --accent-foreground: 240 5.9% 10%;
    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 0 0% 98%;
    --border: 240 5.9% 90%;
    --input: 240 5.9% 90%;
    --ring: 240 10% 3.9%;
    --radius: 0.5rem;
    --chart-1: 173 58% 39%;
    --chart-2: 12 76% 61%;
    --chart-3: 197 37% 24%;
    --chart-4: 43 74% 66%;
    --chart-5: 27 87% 67%;
    --sidebar-background: 0 0% 98%;
    --sidebar-foreground: 240 5.3% 26.1%;
    --sidebar-primary: 240 5.9% 10%;
    --sidebar-primary-foreground: 0 0% 98%;
    --sidebar-accent: 240 4.8% 95.9%;
    --sidebar-accent-foreground: 240 5.9% 10%;
    --sidebar-border: 220 13% 91%;
    --sidebar-ring: 217.2 91.2% 59.8%;
    --status-success: 142 76% 36%;
    --status-success-background: 142 76% 36% / 0.2;
    --status-success-border: 142 76% 36% / 0.3;
    --status-warning: 38 92% 50%;
    --status-warning-background: 38 92% 50% / 0.2;
    --status-warning-border: 38 92% 50% / 0.3;
  }

  .dark {
    --background: 240 10% 3.9%;
    --foreground: 0 0% 98%;
    --card: 240 10% 3.9%;
    --card-foreground: 0 0% 98%;
    --popover: 240 10% 3.9%;
    --popover-foreground: 0 0% 98%;
    --primary: 0 0% 98%;
    --primary-foreground: 240 5.9% 10%;
    --secondary: 240 3.7% 15.9%;
    --secondary-foreground: 0 0% 98%;
    --muted: 240 3.7% 15.9%;
    --muted-foreground: 240 5% 64.9%;
    --accent: 240 3.7% 15.9%;
    --accent-foreground: 0 0% 98%;
    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 0 0% 98%;
    --border: 240 3.7% 15.9%;
    --input: 240 3.7% 15.9%;
    --ring: 240 4.9% 83.9%;
    --chart-1: 173 58% 39%;
    --chart-2: 12 76% 61%;
    --chart-3: 197 37% 24%;
    --chart-4: 43 74% 66%;
    --chart-5: 27 87% 67%;
    --sidebar-background: 240 5.9% 10%;
    --sidebar-foreground: 240 4.8% 95.9%;
    --sidebar-primary: 224.3 76.3% 48%;
    --sidebar-primary-foreground: 0 0% 100%;
    --sidebar-accent: 240 3.7% 15.9%;
    --sidebar-accent-foreground: 240 4.8% 95.9%;
    --sidebar-border: 240 3.7% 15.9%;
    --sidebar-ring: 217.2 91.2% 59.8%;
    --status-success: 142 76% 36%;
    --status-success-background: 142 76% 36% / 0.3;
    --status-success-border: 142 76% 36% / 0.5;
    --status-warning: 38 92% 50%;
    --status-warning-background: 38 92% 50% / 0.3;
    --status-warning-border: 38 92% 50% / 0.5;
  }
}

@layer base {
  * {
    @apply border-border;
    border-color: hsl(var(--border)) !important;
  }
  body {
    @apply bg-background text-foreground;
  }
}

@keyframes slideDown {
  from {
    transform: translateY(-20px);
    opacity: 0;
  }
  to {
    transform: translateY(0px);
    opacity: 1;
  }
}

@keyframes slideUp {
  from {
    transform: translateY(20px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

a:hover {
  cursor: pointer;
}

.slide-down {
  animation: slideDown 0.5s ease-out forwards;
}
.slide-down-quickly {
  animation: slideDown 0.25s ease-out forwards;
}

.slide-up {
  animation: slideUp 0.5s ease-out forwards;
}

.animation-delay-half-s {
  animation-delay: 0.5s;
}

.hero-text {
  font-family: Palatino;
}

.text-default {
  color: black;
}

.dark .text-default {
  color: white;
}

:root {
  --flourish-gradient: linear-gradient(
    135deg,
    rgb(16, 16, 16),
    rgb(32, 32, 32),
    rgb(72, 72, 72),
    rgb(16, 16, 16)
  );
}

.flourish-svg {
  fill: var(--flourish-gradient);
}

/* copper color, best yet
:root {
  --flourish-gradient: linear-gradient(135deg, #B87333 0%, #DA8A67 25%, #E6A977 50%, #DA8A67 75%, #B87333 100%);
}*/

/* Dark mode */
/* VERY GOOD, PRESERVE
.dark {
  --flourish-gradient: linear-gradient(135deg, #A8A9AD 0%, #D9D9D9 25%, #F0F0F0 50%, #D9D9D9 75%, #A8A9AD 100%);
}*/

.dark {
  --flourish-gradient: linear-gradient(
    135deg,
    #a8a9ad 0%,
    #d9d9d9 25%,
    #f0f0f0 50%,
    #d9d9d9 75%,
    #a8a9ad 100%
  );
}

/* Apply the gradient to text */
.flourish-text {
  background: var(--flourish-gradient);
  background-clip: text;
  -webkit-background-clip: text; /* For Safari */
  color: transparent;
}

/* Apply the gradient to background */
.flourish-background {
  color: white;
  background: var(--flourish-gradient);
}
.dark .flourish-background {
  color: black;
}

.gt-panel {
  max-width: 95%;
  background-color: rgba(252, 252, 252, 0.85); /* Slight transparency */
  border: solid thin;
  backdrop-filter: blur(10px); /* Glass effect */
  -webkit-backdrop-filter: blur(10px); /* Safari compatibility */
}

.dark .gt-panel {
  background-color: rgba(
    12,
    12,
    12,
    0.85
  ); /* Adjusted transparency for dark mode */
}

@layer base {
  :root {
    --sidebar-background: 0 0% 98%;
    --sidebar-foreground: 240 5.3% 26.1%;
    --sidebar-primary: 240 5.9% 10%;
    --sidebar-primary-foreground: 0 0% 98%;
    --sidebar-accent: 240 4.8% 95.9%;
    --sidebar-accent-foreground: 240 5.9% 10%;
    --sidebar-border: 220 13% 91%;
    --sidebar-ring: 217.2 91.2% 59.8%;
  }

  .dark {
    --sidebar-background: 240 5.9% 10%;
    --sidebar-foreground: 240 4.8% 95.9%;
    --sidebar-primary: 0 0% 98%;
    --sidebar-primary-foreground: 240 5.9% 10%;
    --sidebar-accent: 240 3.7% 15.9%;
    --sidebar-accent-foreground: 240 4.8% 95.9%;
    --sidebar-border: 240 3.7% 15.9%;
    --sidebar-ring: 217.2 91.2% 59.8%;
  }
}
